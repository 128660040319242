<template>
  <div class="wrap">
    <carouselBanner paddingTop="14.6%" :bannerType="4"/>
    <div class="content_area">
      <searchCompany
        v-model="queryParams.companyName"
        @search="search"
        class="search-wrap"
      />
      <filterSearch @handleSearch="handleSearch" />
      <div class="main">
        <div class="left" style="overflow: auto">
          <div>
            <unitItem
              @refresh="refresh"
              v-for="(item, index) in companyList"
              :number="2"
              :companyInfo="item"
              :key="index"
            />
          </div>
          <isLoadingTip v-if="loading" />
          <isAllDataTip
            v-if="
              companyList.length && total === companyList.length && !loading
            "
          />
          <noDataImg v-if="companyList.length === 0"></noDataImg>
        </div>
        <div class="right">
          <newPerson />
        </div>
      </div>
    </div>
    <videoPlay ref="videoPlay"></videoPlay>
  </div>
</template>

<script>
import filterSearch from "~gbk/components/company/unit/filterSearch";
import carouselBanner from '@/baseComponents/carouselBanner'
import searchCompany from "~gbk/components/company/unit/searchCompany";
import newPerson from "~gbk/components/company/unit/newPerson";
import unitItem from "~gbk/components/company/unit/unitItem";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import videoPlay from "@/baseComponents/videoPlayNoAd.vue";
export default {
  components: {
    carouselBanner,
    searchCompany,
    newPerson,
    unitItem,
    filterSearch,
    isLoadingTip,
    isAllDataTip,
    videoPlay
  },
  metaInfo: {
    title: "企业名录",
  },
  data() {
    return {
      total: 0,
      currentPage: 0,
      companyList: [],
      loading: false,
      queryParams: {
        companyName: "",
      },
      companyName: "",
      filters: {
        city: {},
        unitType: null,
        checkYear: null,
        checkClass: [],
        checkCompaines: [],
        checkLine: [],
      },
      cityType: 0,
    };
  },
  computed: {},
  created() {},
  watch: {
    "$route.query.parameter"() {
      if (this.$route.query.parameter) {
        this.queryParams.companyName = this._decode(
          this.$route.query.parameter
        ).searchVal;
      } else {
        this.queryParams.companyName = "";
      }
      this.currentPage = 0;
      this.getList(1);
    },
  },
  mounted() {
    
    if (this.$route.query.parameter) {
      this.queryParams.companyName = this._decode(
        this.$route.query.parameter
      ).searchVal;
    }
    this.getList(1);
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    async getList(type) {
      window.removeEventListener("scroll", this.scrollEvent, false);
      //type 1是搜索赋值 2是下拉拼接
      this.loading = true;
      let params = {};
      params.start = this.currentPage;
      params.limit = 10;
      params.name = this.queryParams.companyName;
      if (this.filters.city) {
        params.city_id = this.filters.city.city_id;
      }
      if (this.filters.unitType) {
        params.genre_ids = this.filters.unitType;
      }
      if (this.filters.checkCompaines.length) {
        params.company_shipping_id = this.filters.checkCompaines
          .map((item) => item.id)
          .join(",");
      }
      if (this.filters.checkLine.length) {
        params.lanes = this.filters.checkLine.join(",");
      }
      if (this.filters.checkClass.length) {
        params.business_classification = this.filters.checkClass.join(",");
      }
      if (this.filters.checkYear) {
        params.found_year = this.filters.checkYear;
      }
      // params.certificate = 19;
      params.source = this.PJSource;
      params.user_id = this.USER_ID;
      // params.source_type = 1;
      // params.is_vip = 1;
      let res = await this.$store.dispatch(
        "API_company/getCompanyList",
        params
      );
      if (res.success) {
        res.data.forEach((item, index) => {
          if (item.genre_ids.length > 1) {
            item.genre_ids = item.genre_ids.filter((ee) => ee != "1");
          }
        });
        window.addEventListener("scroll", this.scrollEvent, false);
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
          // 过滤显示的视频或图文产品信息
          if(item && item.company_product_info.length > 0){
            let companyProductInfo = [];
            item.company_product_info.map(function (li) {
              if(li.is_show == 1){
                companyProductInfo.push(li);
              }
            });
            item.company_product_info = companyProductInfo;
          }
        });
        this.currentPage++;
        this.loading = false;
        if (type === 1) {
          this.companyList = res.data;
        } else {
          this.companyList = this.companyList.concat(res.data);
        }
        this.total = res.total;
      }
    },
    search() {
      this.currentPage = 0;
      this.getList(1);
    },
    handleCurrentChange() {},
    handleSearch(val) {
      this.filters = val;
      this.currentPage = 0;
      this.getList(1);
    },
    scrollEvent() {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight - 354
      ) {
        if (this.total != this.companyList.length) {
          this.getList(2);
        }
      }
    },
    refresh() {
      this.currentPage = 0;
      this.getList(1);
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fafafa;
}

.content_area {
  .search-wrap {
    text-align: right;
    margin-bottom: 16px;
  }

  .main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: 20px;
      width: 896px;
      position: relative;
    }

    .right {
      margin-top: 20px;
      width: 280px;
    }
  }
}
</style>
