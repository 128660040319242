<template>
  <div>
    <el-radio-group v-model="years" size="mini" style="padding: 15px 0">
      <el-radio-button
          v-for="(item, index) in yearsData"
          :label="item.value"
          :key="index"
          size="small"
      >{{ item.label }}
      </el-radio-button
      >
    </el-radio-group>
    <div style="text-align: right; margin: 0">
      <el-button size="small" type="text" @click="closeYear">{{
          $t("cancel")
        }}
      </el-button>
      <el-button type="primary" size="small" @click="saveYear(years)">{{
          $t("confirm")
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
import {setYears} from '~gbk/utils/enumValue'
export default {
  name: "yearSearch",
  props:['isSelect'],
  data(){
    return {
      years:null,
      yearsData:[]
    }
  },
  watch:{
    isSelect(){
      this.years=this.isSelect
    }
  },
  mounted() {
    if(this.LOCALE === 'en'){
      setYears.forEach((ele) => {
        ele.label = ele.label_en;
      });
    }else {
      setYears.forEach((ele) => {
        ele.label = ele.label_zh;
      });
    }
    this.yearsData = setYears;
  },
  methods:{
    closeYear(){
      this.$emit('closeYear')
    },
    saveYear(value){
      this.$emit('saveYear',value)
    }
  }
}
</script>

<style scoped lang="less">

</style>